<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline';

withDefaults(
  defineProps<{
    logoLinkTo?: string;
    nav?: { name: string; to: string }[];
  }>(),
  {
    logoLinkTo: '/',
    nav: () => [],
  },
);

const mobileMenuOpen = ref(false);

const route = useRoute();
function getLinkClass(to: string) {
  const currentPath = route.path;
  return currentPath === to ? 'text-indigo-600' : 'text-gray-800';
};
</script>

<template>
  <header class="inset-x-0 top-0 z-50">
    <nav class="flex items-center justify-between p-6 lg:px-8 text-gray-800" aria-label="Global">
      <div class="flex lg:flex-1">
        <NuxtLink :to="logoLinkTo" class="text-2xl font-bold flex items-center">
          <img
            src="https://devifex-assets.s3.eu-central-1.amazonaws.com/Logo.svg" alt="Logo"
            width="70" height="70"
            class="w-12 h-12 mr-4"
          > Devifex
        </NuxtLink>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <NuxtLink v-for="item in nav" :key="item.name" :to="item.to" class="font-semibold leading-6" :class="getLinkClass(item.to)">
          {{ item.name }}
        </NuxtLink>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <slot name="right" />
      </div>
    </nav>

    <PSidebar v-model:visible="mobileMenuOpen" position="right">
      <template #header>
        <h3 class="text-xl">
          Menu
        </h3>
      </template>

      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div v-if="nav.length" class="space-y-2 py-6">
            <NuxtLink
              v-for="item in nav" :key="item.to" :to="item.to"
              class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50"
              :class="getLinkClass(item.to)"
              @click="mobileMenuOpen = false"
            >
              {{ item.name }}
            </NuxtLink>
          </div>
          <div class="space-y-4 py-6 flex flex-col">
            <slot name="right" />
          </div>
        </div>
      </div>
    </PSidebar>
  </header>
</template>
